<template>
  <v-container class="px-16">
    <v-row justify="center" class="my-10">
      <h3>자주하는 질문</h3>
    </v-row>
    <v-row style="max-width:70vh" class="mx-auto">
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>~~~ 하나요?</v-expansion-panel-header>
          <v-expansion-panel-content>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam,
            quidem laboriosam voluptates architecto qui doloribus consequatur,
            beatae quos eveniet natus illum ullam eius asperiores necessitatibus
            ducimus, fuga culpa ipsum in.
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>~~~ 맞나요?</v-expansion-panel-header>
          <v-expansion-panel-content>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt
            inventore quae quisquam optio quas dicta illum assumenda similique
            labore facilis, doloribus architecto fugit eum! Repellendus dolorem
            est nihil officiis tenetur.
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>~~~ 인가요?</v-expansion-panel-header>
          <v-expansion-panel-content>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Perferendis blanditiis ea commodi aperiam assumenda quos, vel at
            possimus sunt tempora. Animi vero dolorum sunt nulla eligendi
            explicabo eaque quo asperiores.
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
